/***
*
*   USER
*   shows the current user 
*
**********/

import React, { useContext } from 'react';
import { AuthContext } from 'components/lib';
import Style from './user.module.scss';

export function User(props){

  const authContext = useContext(AuthContext);
  
  return (
    <div className={ Style.user }>
      
      <div className={ Style.name }>Welcome, { authContext.account?.name }</div>

   </div>
  )
}