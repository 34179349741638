import React from 'react';
import Axios from 'axios';

// components
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { View } from 'components/lib';

// 404
import { NotFound } from 'views/error/404';

// tailwind css
import '../css/output.css';

const routes = [

  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,

]

export default function App(props) {

  const account = JSON.parse(localStorage.getItem('account'));
  Axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  if (account?.token) {

    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + account.token;

  }

  // render the routes
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {routes.map(route => {

            return (
              <Route
                key={route.path}
                path={route.path}
                element={

                  route.permission ?
                    <PrivateRoute permission={route.permission}>
                      <View display={route.view} layout={route.layout} title={route.title} />
                    </PrivateRoute> :
                    <View display={route.view} layout={route.layout} title={route.title} />

                }
              />
            )
          })}

          <Route path='/' element={<Navigate to='/signin' replace />} />

          { /* 404 */}
          <Route path='*' element={<View display={NotFound} layout='auth' title='404 Not Found' />} />

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}