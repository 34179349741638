/***
*
*   BUTTON
*   Can be a standard button, icon button or with loading animation
*
*   PROPS
*   text: button label
*   action: callback function executed on click
*   params: object passed to the callback function (optional)
*   color: red/blue (default: green)
*   icon: icon image (optional)
*   iconPack: icon pack to use
*   iconSize: icon size
*   iconButton: true or false
*   alignIcon: left or right
*   small: render a smaller button
*   textOnly: text only
*   outline: outline button
*   rounded: round the corners
*   className: pass a custom class object
*   fullWidth: extend to full width of parent container
*   loading: boolean to toggle loading animation (optional)
*
**********/

import React, { Fragment, forwardRef, useState } from 'react';
import { Icon, ClassHelper, useNavigate, Tooltip } from 'components/lib';

import ButtonStyle from './button.tailwind.js';
import IconStyle from './icon.tailwind.js';

export const Button = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const buttonStyle = ClassHelper(ButtonStyle, {
    ...props, ...{

      ...props.color && { [props.color]: true },
      [props.position || 'relative']: true,
      text: props.textOnly,
      iconText: props.icon && props.text,
      iconTextOnly: props.textOnly && props.icon && props.text,
      btn: props.iconButton || (!props.textOnly && !props.icon),
      btnText: !props.outline && !props.iconOnly,
      ...!props.color && props.text && !props.icon && !props.textOnly && !props.outline && {

        // default color
        green: true

      }
    }
  });

  const iconStyle = ClassHelper(IconStyle, {

    fill: props.fillIcon,
    alignLeft: props.alignLeft,
    alignRight: props.alignRight,
    alignCenter: props.alignCenter,
    insideButton: props.iconButton || (!props.textOnly && !props.icon),

  })

  return (
    <button
      ref={ref}
      title={props.title}
      className={buttonStyle}
      onClick={e => {

        e.preventDefault();
        e.stopPropagation();

        props.action && props.action(props.params);
        props.goto && navigate(props.goto.to, { state: props.goto.state });
        if (props.url) window.location = props.url;
        if (props.externalLink) window.open(props.externalLink);

      }}
      onMouseEnter={() => props.tooltip && setDisplayTooltip(true)}
      onMouseLeave={() => props.tooltip && setDisplayTooltip(false)}
    >

      {props.icon ?

        <Fragment>
          <Icon
            image={props.icon}
            pack={props.iconPack}
            color={props.iconColor || props.color}
            size={props.iconSize || props.size || 18}
            className={iconStyle}
          />
          {props.text && props.text}
        </Fragment>

        : props.text

      }
      {props.tooltip && <Tooltip text={props.tooltip} show={displayTooltip} />}
    </button>
  );
});

