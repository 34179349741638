/***
*
*   useAPI hook
*   Interact with API calls – handle errors, return loading state and data
*
*   PROPS
*   url: endpoint url
*   method: get, post, put etc.. (default: get)
*
**********/

import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import Axios from 'axios';
import { ViewContext } from 'components/lib';

export function useAPI(url, displayError, method) {

  // wrap in useRef to prevent triggering useEffect multiple times  
  const context = useRef(useContext(ViewContext));
  const [state, setState] = useState({ data: null, loading: false })

  const fetch = useCallback(async () => {
    try {

      if (!url) {

        setState({ data: null, loading: false });
        return false;

      }

      setState({ loading: true });
      const res = await Axios({

        url: url,
        method: method || 'get',

      })

      setState({ data: res.data.data, loading: false });

    }
    catch (err) {

      if (displayError) {
        if (context?.current) {
          context.current.handleError(err);
        }
        else if (err.response.status >= 400 && err.response.data?.message === 'jwt expired') {
          Axios({ method: 'delete', url: '/api/auth' });
          localStorage.clear();
          return window.location = '/signin'
        }
      }
      else {
        setState({ error: err?.response?.data, loading: false });
      }

    }
  }, [url, method, context, displayError]);

  useEffect(() => {

    fetch()

  }, [fetch]);

  return state

}
