/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
**********/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

// auth context
export const AuthContext = React.createContext();

const useAPI = require('components/lib').useAPI;
const Event = require('components/lib').Event;
const permissions = require('./permissions.json');

export function AuthProvider(props){

  const cache = JSON.parse(localStorage.getItem('account'));
  const [account, setAccount] = useState(cache);
  const auth = useAPI(account ? '/api/auth' : null, account ? true : false);

  useEffect(() => {

    // update the auth status
    if (!auth.loading && auth.data){

      auth.data.authenticated ? 
        update(auth.data) : signout();

    }
  }, [auth]);

  const signin = res => {

    if (res.data){

      localStorage.setItem('account', JSON.stringify(res.data));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
      Event('signin');

      return window.location = res.data.onboarded ? '/dashboard' : '/welcome';

    }
  }

  const signout = async () => {

    await axios.delete('/api/auth');
    localStorage.clear();
    window.location = '/signin';

  }

  const update = data =>{

    if (localStorage.getItem('account')){

      let account = JSON.parse(localStorage.getItem('account'));
      for (let key in data){

        if (Array.isArray(data[key])){
       
          account[key] = data[key]

        }
        else if (typeof data[key] === 'object'){
          for (let innerKey in data[key]){

            account[key][innerKey] = data[key][innerKey]

          }
        }
        else {
          
          account[key] = data[key];

        }
      }

      localStorage.setItem('account', JSON.stringify(account));
      setAccount(account);

    }
  }

  return (
    <AuthContext.Provider value={{

      account: account,
      signin: signin,
      signout: signout,
      update: update,
      permission: permissions[account?.permission]

    }}

    {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props){

  // check account exists
  const account = JSON.parse(localStorage.getItem('account'));

  if (account?.token){
    if (permissions[account.permission][props.permission]){

      return props.children;

    }
  }

  // account is not authenticated
  return <Navigate to='/signin' />;

}
