/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, Stat, Chart, Grid, Animate, AuthContext, Loader, ViewContext } from 'components/lib';
import axios from 'axios';

export function Dashboard(props) {

  const authContext = useContext(AuthContext);
  const viewContext = useRef(useContext(ViewContext));

  const [loaded, setLoaded] = useState(false);
  const [aiCredits, setAiCredits] = useState();
  const [creditsUsedToday, setCreditsUsedToday] = useState();
  const [creditsGraphData, setCreditsGraphData] = useState();
  const [apiUsageData, setApiUsageData] = useState();
  const [creditsDateFilter, setCreditsDateFilter] = useState(false);
  const [apiUsageDateFilter, setApiUsageDateFilter] = useState(false);

  const getConsumedCredits = useCallback(async (graph, startDate, endDate) => {
    try {
      const { data } = await axios.get(`/api/api-logs/account-id/${authContext.account.account_id}/${graph}${startDate ? '?startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`);

      graph === 'credits'
        ? setCreditsGraphData(data.data)
        : setApiUsageData(data.data);

    } catch (error) {
      viewContext?.current &&
        viewContext.current.handleError(error);
    }
  }, [authContext?.account?.account_id])

  const getCreditsUsedToday = async () => {
    try {
      const { data } = await axios.get(`/api/api-logs/account-id/${authContext.account.account_id}/credits/today`);
      setCreditsUsedToday(data.data);

    } catch (error) {
      viewContext?.current &&
        viewContext.current.handleError(error);
    }
  }

  const getAiCredits = async () => {
    try {
      const { data } = await axios.get(`/api/ai-credits/account-id/${authContext.account.account_id}`);
      setAiCredits(data.data);
    } catch (error) {
      viewContext?.current &&
        viewContext.current.handleError(error);
    }
  }

  useEffect(() => {
    if (authContext?.account && !loaded) {
      getAiCredits();
      getCreditsUsedToday();
      getConsumedCredits('credits');
      getConsumedCredits('apis');
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext, loaded])


  if (aiCredits === undefined || creditsUsedToday === undefined)
    return <Loader />

  return (
    <>
      <Animate type='pop'>
        <Grid cols='2'>
          <Stat
            loading={!aiCredits}
            value={aiCredits?.credits || 0}
            label='credits left'
            icon='check-circle'
          />

          <Stat
            value={creditsUsedToday}
            label='credits used today (GMT)'
            icon='dollar-sign'
          />
        </Grid>

        <Card
          title={'Credits used and total API calls' + (!creditsDateFilter ? ' for the past 30 days' : '') + ' (GMT)'}
          dateFilter={dates => {
            setCreditsGraphData();
            getConsumedCredits('credits', dates?.[0], dates?.[1]);
            setCreditsDateFilter(dates ? true : false);
          }}
        >
          <Chart
            type={creditsGraphData?.labels?.length === 1 ? 'bar' : 'line'}
            data={creditsGraphData}
            loading={!creditsGraphData}
          />
        </Card>

        <Card
          title={'API usage' + (!apiUsageDateFilter ? ' for the past 30 days' : '') + ' (GMT)'}
          dateFilter={dates => {
            setApiUsageData();
            getConsumedCredits('apis', dates?.[0], dates?.[1]);
            setApiUsageDateFilter(dates ? true : false);
          }}
        >
          <Chart
            type='bar'
            color='green'
            data={apiUsageData}
            loading={!apiUsageData}
          />
        </Card>

      </Animate>
    </>
  );
}