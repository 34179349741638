const Style = {

  base: 'relative text-md p-6 x-1 mb-6 bg-white rounded last-of-type:mb-0',
  title: 'float:left m-0 text-lg font-semibold',
  center: 'mx-auto',
  loading: 'h-60',
  shadow: 'drop-shadow-sm',
  noPadding: '!p-0',
  restrictWidth: 'max-w-md',
  last: 'mb-0',
  bottomMargin: '!mb-10'

}

export default Style