import { Account } from 'views/account';
import { Profile } from 'views/account/profile';
import { Password } from 'views/account/password';
import { TwoFA } from 'views/account/2fa';
import { APIKeyList } from 'views/account/apikey/list';
import { APIKeyEditor } from 'views/account/apikey/create';

const Routes = [
  {
    path: '/account',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Your Account'
  },
  {
    path: '/account/profile',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'Your Profile'
  },
  {
    path: '/account/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'Change Password'
  },
  {
    path: '/account/2fa',
    view: TwoFA,
    layout: 'app',
    permission: 'user',
    title: 'Manage 2FA'
  },
  {
    path: '/account/api-keys',
    view: APIKeyList,
    layout: 'app',
    permission: 'user', 
    title: 'API Keys'
  },
  {
    path: '/account/api-keys/generate',
    view: APIKeyEditor,
    layout: 'app',
    permission: 'user', 
    title: 'API Keys'
  }
]

export default Routes;