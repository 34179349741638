import React, { useState } from 'react';
import { Label } from 'components/lib';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import InputStyle from '../input.module.scss';
import classNames from 'classnames';
import Style from './date.module.scss';

export function DateInput(props) {
  const isRange = props.type === 'range';
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });
  const [rangeDisplay, setRangeDisplay] = useState();
  const [open, setOpen] = useState(false);

  const css = classNames([Style.date, isRange && Style.range, props.clear && Style.clearable, props.className]);
  const wrapperCss = classNames([Style.dateWrapper, open && Style.open])

  // init default value
  let date = props.value;

  if (isRange && date && date.indexOf('-') && date.length >= 10) {

    date = date.split('-');

    date = {

      year: parseInt(date[0]),
      month: parseInt(date[1]),
      day: parseInt(date[2])

    }
  }
  else {

    const now = new Date();
    date = {

      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()

    }
  }

  const formatDateString = d => {

    return `${d.day < 10 ? '0' + d.day : d.day}/${d.month < 10 ? '0' + d.month : d.month}/${d.year}`

  }

  const change = date => {

    let valid;

    if (isRange) {
      setSelectedDayRange(date);

      if (date.from && date.to) {
        if (props.required)
          valid = date.from.year && date.from.day && date.from.month
            && date.to.year && date.to.day && date.to.month ? true : false;

        const fromFormatted = formatDateString(date.from);
        const toFormatted = formatDateString(date.to);

        setRangeDisplay(`${fromFormatted}  to  ${toFormatted}`);
        props.onChange(props.name, [fromFormatted, toFormatted], valid);
      }
    }
    else if (props.required) {
      valid = date.year && date.day && date.month ? true : false;
      props.onChange(props.name, formatDateString(date), valid);
    }
  }

  const reset = () => {
    date = '';
    setSelectedDayRange({ from: null, to: null });
    setRangeDisplay('');
    props.onChange(props.name, '', !props.required);
  }

  const customInput = ({ ref }) => {

    return (
      <input
        ref={ref}
        value={(isRange ? rangeDisplay : props.value?.split('T')[0]) || ""}
        placeholder={props.placeholder || 'Please select a date'}
        className={InputStyle.textbox}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        readOnly
      />
    );
  }

  return (
    <div className={css}>

      {props.label &&
        <Label text={props.label} />}

      <DatePicker
        value={isRange ? selectedDayRange : date}
        onChange={change}
        minimumDate={props.min}
        maximumDate={props.max}
        colorPrimary='#73B0F4'
        wrapperClassName={wrapperCss}
        renderInput={customInput}
        calendarPopperPosition='bottom'
        inputClassName={InputStyle.textbox}
      />

      {props.clear &&
        <button onClick={reset}>X</button>
      }
    </div>
  )
}