/***
*
*   PROFILE
*   Update the account profile
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Form, Card, AccountNav, Animate, useAPI } from 'components/lib';

export function Profile(props){

  // context
  const authContext = useContext(AuthContext);

  // fetch
  const account = useAPI('/api/accounts', true);

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card
          title='Edit Your Profile'
          loading={ account.loading } restrictWidth>

          { account?.data &&
            <Form
              buttonText='Save'
              buttonColor='purple'
              url='/api/accounts'
              method='PATCH'
              data={{
                name: {
                  label: 'Account Name',
                  type: 'text',
                  required: true,
                  value: account.data.name,
                  errorMessage: 'Please enter your account name',
                },
                email: {
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  value: account.data.email,
                  errorMessage: 'Please enter your email address'
                }
              }}
              callback={ res => {

                // update the account name
                authContext.update({ name: res.data.data.name })

              }}
            />
          }

        </Card>
      </Animate>
    </Fragment>
  );
}
