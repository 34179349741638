const Style = {

  base: 'underline break-words',
  defaultColor: 'text-blue-500 hover:text-blue-700',
  white: 'text-white',
  dark: 'text-slate-500',
  purple: 'text-brand-purple-500 hover:text-brand-purple-700'

}

export default Style;