/***
*
*   API Key Editor
*   Create or edit a new/existing API key
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import {
  Animate, AccountNav, Card, Message, Breadcrumbs, TextInput,
  Loader, useLocation, useAPI, ViewContext, Fieldset, Button
} from 'components/lib';

export function APIKeyEditor() {
  const location = useLocation();
  const context = useContext(ViewContext);

  // get the scopes
  const scopes = useAPI(`/api/scopes/api-key`, true);

  // state
  const [hasKey, setHasKey] = useState();
  const [newAPIKey, setNewAPIKey] = useState(false);
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    const getKey = async () => {
      const { data } = await Axios.get(`/api/key`);
      setHasKey(data.data ? true : false);
    }

    location.state?.hasKey ? setHasKey(location.state.hasKey) : getKey();
  }, [location.state])

  const submitForm = async () => {
    setGenerating(true);
    const { data } = await Axios.post('/api/key', { scope: scopes.data });
    setNewAPIKey(data?.data?.full_key);
    setGenerating(false);
  }

  const create = () => {
    if (hasKey) {
      context.modal.show({
        title: 'Generate API key',
        form: {},
        text: 'Your existing API key will be overwritten if you generate a new key. Do you want to continue?',
        buttonText: 'Continue',
        destructive: true

      }, () => {
        submitForm();
        context.modal.hide(true);
      })
    }
    else {
      submitForm();
    }
  }

  if (scopes.loading || hasKey === undefined)
    return <Loader />

  return (
    <Animate>
      <AccountNav />

      <Breadcrumbs items={[
        { name: 'API Keys', url: '/account/api-keys' },
        { name: 'Generate API Key', url: '/account/api-keys/generate' }
      ]} />

      {newAPIKey ?
        <Message
          title='Your New API Key'
          type='warning'
          buttonText='Back to API Keys'
          buttonLink='/account/api-keys'
          text='Your API key is below. Please store this somewhere safe.'>

          <TextInput value={newAPIKey} />

        </Message> :

        <Card title='Generate API Key' loading={scopes.loading}>
          {generating
            ? <Loader marginTop />
            : <>
              <Fieldset
                type='checkbox'
                label='Scope'
                disabled={true}
                default={location?.state?.scope || scopes?.data}
                options={scopes?.data}
              />
              <Button color='purple' text='Generate' alignCenter action={create} />
            </>
          }
        </Card>
      }
    </Animate>
  )
}