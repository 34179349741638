// import classNames from 'classnames';
import { ClassHelper } from 'components/class';
import Style from './tooltip.module.scss';

export function Tooltip({ text, show }) {
    const messageStyle = ClassHelper(Style, {

        tooltip: true,
        visible: show,

    });

    return (
        <>
            <div className={messageStyle}>
                {text}
            </div>
            <span className={Style.arrow} />
        </>
    );
}