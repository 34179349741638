/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Onboarding } from 'components/lib';

export function OnboardingView(props) {

  const context = useContext(AuthContext);
  const views = [{

    name: 'Getting Started',
    description: `Welcome, ${context.account.name}!`,
    component: <Welcome />

  }]

  return (
    <Onboarding
      save
      onFinish={
        context.account.has_default_password
          ? '/account/password' : '/dashboard'
      }
      views={views}
    />
  );

}


const Welcome = () => {

  return (

    <p>Welcome to the InspireMe GenAI Platform Dashboard</p>

  )
}