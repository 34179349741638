import { Signin } from 'views/auth/signin';
import { SigninOTP } from 'views/auth/signin/otp';
import { ForgotPassword } from 'views/auth/signin/forgotpassword';
import { ResetPassword } from 'views/auth/signin/resetpassword';
import { MagicSignin } from 'views/auth/signin/magic';
import { ImpersonateSignin } from 'views/auth/signin/impersonate';

const Routes = [
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Welcome to InspireMe GenAI Platform'
  },
  {
    path: '/signin/otp',
    view: SigninOTP,
    layout: 'auth',
    title: 'Enter verification code'
  },
  {
    path: '/magic',
    view: MagicSignin,
    layout: 'auth',
    title: 'Sign In'
  },
  {
    path: '/forgotpassword',
    view: ForgotPassword,
    layout: 'auth',
    title: 'Forgot Your Password?'
  },
  {
    path: '/resetpassword',
    view: ResetPassword,
    layout: 'auth',
    title: 'Reset Your Password'
  },
  {
    path: '/signin/impersonate',
    view: ImpersonateSignin,
    layout: 'auth',
    title: 'Sign in via Admin'
  },
]

export default Routes;