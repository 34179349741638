/***
*
*   PASSWORD
*   Update the users password
*
*   if the user has a password show old and new inputs
*   if no existing password
*   then allow the user to set a password on their account
*
**********/

import React, { Fragment, useContext, useState } from 'react';
import { AuthContext, AccountNav, Animate, Card, Form, Message } from 'components/lib';

export function Password(props) {

  const context = useContext(AuthContext);
  const [done, setDone] = useState(false);

  return (
    <Fragment>

      <AccountNav />
      <Animate>
        <Card title='Update Your Password' restrictWidth className={props.className}>

          {!done ? (
            <>
              {context.account.has_default_password && (
                <Message
                  title='Change password'
                  type='warning'
                  text='This account is using a preset password. We strongly recommmend updating your password.' />
              )}
              <Form
                url='/api/accounts/password'
                method='PUT'
                buttonText='Save Password'
                buttonColor='purple'
                data={{
                  ...context.account.has_password && {
                    oldpassword: {
                      label: 'Current Password',
                      type: 'password',
                      required: true
                    },
                    has_password: {
                      type: 'hidden',
                      value: true,
                    }
                  },
                  newpassword: {
                    label: 'Create New Password',
                    type: 'password',
                    required: true,
                    complexPassword: true
                  },
                }}
                callback={() => {

                  setDone(true);
                  context.update({ has_password: true, has_default_password: false });

                }}
              />
            </>
          ) : (
            <Message
              title='Password Saved'
              type='success'
              text='Your new password has been saved successfully.'
            />
          )}
        </Card>
      </Animate>

    </Fragment>
  );
}
