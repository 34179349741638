/***
*
*   LINE DIVIDER
*   that can have 'or' text in the middle
*
**********/

import React from 'react'
import Style from './divider.module.scss';

export function Divider({ showOr }) {
    return <p className={Style.divider}>{showOr && <span>OR</span>}</p>
}