/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';
import { Animate, Row, Content, Link, ClassHelper } from 'components/lib'
import Style from './footer.tailwind.js';

export function Footer(props) {

  const footerStyle = ClassHelper(Style, {

    footer: true,
    className: props.className,

  });

  const copyrightStyle = ClassHelper(Style, {

    copyright: true,

  });
  
  const addressStyle = ClassHelper(Style, {

    address: true,

  });

  return (
    <Animate type='slideup'>
      <footer className={footerStyle}>
        <Row>
          <Content>

            <nav className={Style.nav}>
              <Link
                url='https://inspireme.ai/terms-of-service/'
                text='Terms'
                className={Style.link}
                color='dark'
                rel='noopener noreferrer'
                target='_blank'
              />
              <Link
                url='https://inspireme.ai/privacy-policy/'
                text='Privacy'
                className={Style.link}
                color='dark'
                rel='noopener noreferrer'
                target='_blank'
              />
              <Link
                url='mailTo:support@inspireme.freshdesk.com'
                text='Contact'
                className={Style.link}
                color='dark'
                rel='noopener noreferrer'
                target='_blank'
              />
            </nav>

            <div className={copyrightStyle}>{'Copyright © 2022. BRANDSCAN Pty Ltd. All Rights Reserved.'}</div>
            <address className={addressStyle}>BRANDSCAN PTY LTD 81-83 Campbell Street, Surry Hills, NSW 2010, Australia</address>

          </Content>
        </Row>
      </footer>
    </Animate>
  );
}