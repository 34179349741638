/***
*
*   API Keys List
*   List & manage the API keys
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import {
  ViewContext, Animate, AccountNav, Button, Card, Table, TitleRow,
  Loader, useAPI, Divider, Message
} from 'components/lib';


export function APIKeyList(props) {
  const key = useAPI('/api/key', true);
  const account = useAPI('/api/accounts', true);
  const [apiKey, setApiKey] = useState();

  useEffect(() => {

    if (key?.data)
      setApiKey(key.data);

  }, [key]);

  if (key.loading || account.loading) return <Loader />;

  return (
    <Fragment>
      <AccountNav />

      <Animate>
        <TitleRow title='Your Listed Domains' />
        <Card bottomMargin>
          {!account?.data?.domains?.length
            ? <p>No domains listed</p>
            : account.data.domains?.map(domain => <p key={domain}>{domain}</p>)
          }
          <Divider />
          <p>To update your listed domains, please contact an admin owner.</p>
        </Card>
      </Animate>

      <Animate>
        <APISection
          apiKey={apiKey}
          loading={key.loading}
          handleKey={data => setApiKey(prev => data ? { ...prev, ...data } : data)}
        />
        {account?.data?.ai_key_required && <ReqHeaderMessage />}
      </Animate>
    </Fragment>
  );
}

const APISection = (props) => {
  const viewContext = useContext(ViewContext);

  const revoke = data => {
    viewContext.modal.show({
      title: 'Revoke API Key',
      form: {
        active: {
          type: 'hidden',
          value: false,
        }
      },
      text: 'Are you sure you want to revoke this API Key? This action cannot be undone.',
      buttonText: 'Revoke',
      url: `/api/key/${data.id}`,
      destructive: true,
      method: 'PATCH',

    }, () => {

      props.handleKey({ active: false });

    });
  }

  const del = data => {
    viewContext.modal.show({
      title: 'Delete API Key',
      form: {},
      text: 'Are you sure you want to delete this API Key? This action cannot be undone.',
      buttonText: 'Delete Key',
      url: `/api/key/${data.id}`,
      destructive: true,
      method: 'DELETE',

    }, () => {

      props.handleKey(null);

    });
  }

  const reveal = async data => {

    // reveal the api key
    const key = (await Axios.get(`/api/key/${data.id}`))?.data?.data?.key;
    props.handleKey({ key });

  }

  return (
    <>
      <TitleRow title='Your API Key'>
        <Button
          small
          text='Generate New API Key'
          goto={{
            to: '/account/api-keys/generate',
            state: { hasKey: props.apiKey ? true : false }
          }}
          color='purple'
        />
      </TitleRow>
      <Card bottomMargin>
        {props.apiKey ?
          <Table
            data={[props.apiKey]}
            loading={props.loading}
            show={['key', 'active']}
            actions={{
              custom: [

                { icon: 'eye', action: reveal, tooltip: 'Reveal' },
                { icon: 'rotate-ccw', action: revoke, condition: { col: 'active', value: true }, tooltip: 'Revoke' }
              ],
              delete: del,
            }}
            badge={{
              col: 'active', color: 'green', condition: [

                { value: true, color: 'green' },
                { value: false, color: 'red' }

              ]
            }}
          />
          : <p>No API Key generated</p>
        }
      </Card>
    </>
  )
}

const ReqHeaderMessage = () => (
  <Message type='warning' title='Additional API request header'>
    <p>
      When making <code>generate-ai</code> API requests, an AI-Key header with the value being your own AI (e.g. OpenAI) key will be required.
    </p>

    <p style={{ margin: '24px 0 4px' }}>Example:</p>
    <code style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{'{'}</span>
      <span style={{ paddingLeft: 24 }}>{'headers: {'}</span>
      <span style={{ paddingLeft: 48 }}>{'Authorization: Basic <YOUR_API_KEY>,'}</span>
      <span style={{ paddingLeft: 48 }}>{'AI-Key: <YOUR_OPENAI_API_KEY>'}</span>
      <span style={{ paddingLeft: 24 }}>{'}'}</span>
      <span>{'}'}</span>
    </code>
  </Message>
)