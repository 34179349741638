/***
*
*   CARD
*   Universal container for grouping UI components together
*
*   PROPS
*   title: title string (optional)
*   loading: boolean to toggle the loading animation (optional)
*   shadow: apply a box shadow
*   center: center align the card
*   noPadding: remove the padding
*   restrictWidth: restrict the width of the card on large screens
*
**********/

import React from 'react';
import { Loader, ClassHelper, DateInput } from 'components/lib';
import Style from './card.tailwind.js';
import HeaderStyle from './header.tailwind.js';

export function Card(props) {

  const cardStyle = ClassHelper(Style, {

    shadow: props.shadow,
    center: props.center,
    noPadding: props.noPadding,
    loading: props.loading,
    className: props.className,
    last: props.last,
    restrictWidth: props.restrictWidth,
    bottomMargin: props.bottomMargin

  });

  const headerStyle = ClassHelper(HeaderStyle, {

    flex: !!props.dateFilter

  });

  return (
    <section className={cardStyle}>

      {props.title &&
        <header className={headerStyle}>
          <h1 className={Style.title}>{props.title}</h1>
          {props.dateFilter &&
            <DateInput
              type='range'
              placeholder='Filter by date range'
              onChange={(_, dates) => props.dateFilter(dates)}
              clear
            />
          }
        </header>
      }

      {props.loading ?
        <Loader /> :
        props.children
      }
    </section>
  );
}