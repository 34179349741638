/***
*
*   ACCOUNT
*   Index page for account functions
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Grid, Card, Icon, Link, useAPI, Loader } from 'components/lib';

export function Account(props){

  const context = useContext(AuthContext);
  const account = useAPI('/api/accounts', true);
  const iconSize = 20;

  if (account.loading)
    return <Loader />

  return (
    <Animate>
      <Grid cols='4'>

        <Card>

          <Icon image='user' size={ iconSize }/>
          <h2 className={ Style.subtitle }>Profile</h2>
          <div>Update your profile information</div>
          <Link url='/account/profile' className={ Style.link }>
            Update
          </Link>

        </Card>

        <Card>

          <Icon image='lock' size={ iconSize }/>
          <h2 className={ Style.subtitle }>Password</h2>
          <div>{ account?.data?.['has_password'] ? 'Change your password' : 'Create a password' }</div>
          <Link url='/account/password' className={ Style.link }>
            { account?.data?.['has_password'] ? 'Change' : 'Create' }
          </Link>

        </Card>

        <Card>

          <Icon image='shield' size={ iconSize }/>
          <h2 className={ Style.subtitle }>Two-Factor Authentication</h2>
          <div>Secure your account</div>
          <Link url='/account/2fa' className={ Style.link }>
            { account?.data?.['2fa_enabled'] ? 'Manage' : 'Enable' }
          </Link>

        </Card>

        { context.permission?.user &&
          <Card>

            <Icon image='settings' size={ iconSize }/>
            <h2 className={ Style.subtitle }>API Keys</h2>
            <div>Manage your API keys</div>
            <Link url='/account/api-keys' className={ Style.link }>
              Manage
            </Link>

          </Card>
        }

      </Grid>
    </Animate>
  )
}

const Style = {

  subtitle: 'font-bold mt-4',
  link: 'text-blue-500 font-semibold mt-3 inline-block'

}