/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options for account settings.
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function AccountNav(props){

  return(
    <SubNav items={[

      { link: '/account/profile', label: 'Profile' },
      { link: '/account/password', label: 'Password' },
      { link: '/account/2fa', label : '2FA' },
      { link: '/account/api-keys', label: 'API Keys', permission: 'user' }

    ]}/>
  );
}