/***
*
*   HOME NAV
*   Navigation used on the main external website.
*
**********/

import React from 'react';
import { Logo, Button, Content, ClassHelper, useLocation } from 'components/lib';
import Style from './home.tailwind.js';

export function HomeNav(props) {
  const location = useLocation();
  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent

  });

  return (
    <section className={css}>
      <Content>

        <Logo className={Style.logo} />
        <nav className={Style.nav}>
          {location.pathname !== '/signin' && <Button small goto={{ to: '/signin' }} text='Sign In' className={Style.button} color='purple' />}
        </nav>
      </Content>
    </section>
  );
}